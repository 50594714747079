/*global TARGET_DOMAIN*/
import { createElementFromHTML, isIE, transformJSONToCSS } from './utils';
import { ErrorHandler, Helpers } from '../helpers';
import DialogHandler from './dialog-handler';
import SettingsHandler from './settings-handler';

const css = require(`./css/style-${TARGET_DOMAIN}.scss`).toString();
const framecss = require(`./css/frame-${TARGET_DOMAIN}.scss`).toString();
const bottomRightCSS = require(`./css/bottomright-${TARGET_DOMAIN}.scss`).toString(); // eslint-disable-line
const bottomRightFrameCSS = require('./css/bottomrightframe.scss').toString(); // eslint-disable-line
const bottomLeftCSS = require('./css/bottomleft.scss').toString(); // eslint-disable-line
const bottomLeftFrameCSS = require('./css/bottomleftframe.scss').toString(); // eslint-disable-line
const topLeftCSS = require('./css/topleft.scss').toString(); // eslint-disable-line
const topLeftFrameCSS = require('./css/topleftframe.scss').toString(); // eslint-disable-line
const topRightCSS = require('./css/topright.scss').toString(); // eslint-disable-line
const topRightFrameCSS = require('./css/toprightframe.scss').toString(); // eslint-disable-line
const animateCSS = require('animate.css').toString();


const DISABLEANITATIONMAINCSS = `
#web-messenger-container * {
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}
`;
const DISABLEANITATIONFRAMECSS = `
* {
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}
`;

class StyleHandler {
    constructor({
        getSetting, Platform
    }) {
        this.getSetting = getSetting;
        this.button = DialogHandler.button;
        this.conversation = DialogHandler.conversation;
        this.header = DialogHandler.header;
        this.wrapper = DialogHandler.wrapper;
        this.footer = DialogHandler.footer;
        this.container = DialogHandler.container;
        this.Platform = Platform;
        this.updateCssStrings = this.updateCssStrings.bind(this);
        this.fixMessageStyle = this.fixMessageStyle.bind(this);
        this.moveStyleFromElementToFrameHead = this.moveStyleFromElementToFrameHead.bind(this);
        this.removeHardStyles = this.removeHardStyles.bind(this);
        this.removeConnectNotificationIfFound = this.removeConnectNotificationIfFound.bind(this);
        this.addOverruledAgentLogoIfConfigured = this.addOverruledAgentLogoIfConfigured.bind(this);
        this.addStylesForOrientation = this.addStylesForOrientation.bind(this);
        this.init = this.init.bind(this);
        this.clearHardStyleWhileTyping = this.clearHardStyleWhileTyping.bind(this);
        this.hideWidgetWhileLoading = this.hideWidgetWhileLoading.bind(this);
    }

    static get displayStyle() {
        // we get the display style from looking at dom, not from settings
        // as the settings in the json might be not what sunshine thinks it is somehow
        // and when sunshine thinks it is bar and we load button settings, al goes awire
        if (DialogHandler.frame.getElementById('messenger-button')) { // || getSetting('buttonImage')) {
            return 'button';
        }
        return 'bar';
    }

    init() {
        // animations (see https://daneden.github.io/animate.css/)
        StyleHandler.addStyleText(animateCSS);
        StyleHandler.addStyleText(animateCSS, DialogHandler.frame);
        // hide the widget until were done
        this.hideWidgetWhileLoading();

        // the css we inject, needs to get width and height settings injected
        this.updateCssStrings();

        // remove all dom style attributes that might interfere
        this.removeHardStyles();

        this.addStylesForOrientation();

        // clear hard style of sunshine while typing message
        this.clearHardStyleWhileTyping();

        // add overruling agent logo if configured
        this.addOverruledAgentLogoIfConfigured();

        // overrule with css from appid.json
        const frameStyle = this.getSetting('style.frame');
        if (frameStyle) {
            if (frameStyle.url) {
                // url is pointing to external url, inject it in iframe.
                StyleHandler.addStyle(frameStyle.url, DialogHandler.frame);
                delete frameStyle.url;
            }
            const style = transformJSONToCSS(frameStyle);
            if (style) StyleHandler.addStyleText(style, DialogHandler.frame);
        }
        const bodyStyle = this.getSetting('style.body');
        if (bodyStyle) {
            if (bodyStyle.url) {
                // url is pointing to external url, inject it in body.
                StyleHandler.addStyle(bodyStyle.url);
                delete bodyStyle.url;
            }
            const style = transformJSONToCSS(bodyStyle);
            if (style) StyleHandler.addStyleText(style);
        }

        // company logo setting?
        const companyImageUrl = this.getSetting('companyImageUrl');
        if (companyImageUrl) {
            const logo = this.container.getElementsByClassName('app-icon')[0];
            if (logo) logo.setAttribute('src', companyImageUrl);
        }

        // upload button color
        const uploadButtonColor = this.getSetting('uploadButtonColor');
        if (uploadButtonColor) {
            StyleHandler.setUploadButtonColor(uploadButtonColor);
        }
        const wmFrame = DialogHandler.getWebmessengerFrame();
        // set display style on iframe
        if (StyleHandler.displayStyle === 'button') {
            Helpers.addClass(wmFrame, 'smooch_button');
            const buttonImage = this.getSetting('buttonImage', this.getSetting('buttonIconUrl'));
            if (buttonImage) {
                this.updateButtonImage(buttonImage);
            }
        } else {
            Helpers.addClass(wmFrame, 'smooch_bar');
        }

        // frame class for styling frame when opened or closed
        Helpers.addClass(wmFrame, `${TARGET_DOMAIN}_close`);

        // add css tag when on mobile
        if (this.Platform.isOnMobile()) {
            Helpers.addClass(wmFrame, `${TARGET_DOMAIN}_mobile`);
        }

        if (isIE()) {
            Helpers.addClass(this.wrapper, 'IE');
        }

    }

    static setUploadButtonColor(color) {
        DialogHandler.frame.getElementById('Close-Icon').setAttribute('fill', color);
    }

    updateFrameWidthToImageWidthWhenImageIsLoaded(oldWidth) {
        setTimeout(() => {
            try {
                // update global button
                if (DialogHandler.frame) {
                    this.button = DialogHandler.frame.getElementById('messenger-button');
                    if (this.button) {
                        const imageWidth = this.button.getElementsByTagName('img')[0].clientWidth;
                        if (imageWidth !== oldWidth) {
                            const imageHeight = this.button.getElementsByTagName('img')[0].clientHeight;
                            const fr = DialogHandler.getWebmessengerFrame();
                            fr.setAttribute('width', `${imageWidth}px`);
                            fr.setAttribute('height', `${imageHeight + 30}px`); // 30 is the bottom/top margin (hack)
                            // correct width to width of image
                            this.button.setAttribute('style', `width: ${imageWidth}px; height: ${imageHeight}px;`
                                + 'background-color: inherit; box-shadow: inherit;');
                            this.button.addEventListener('click', DialogHandler.openWidget.bind(this, {}));
                        } else {
                            // we try again in x ms , see below
                            this.updateFrameWidthToImageWidthWhenImageIsLoaded(oldWidth);
                        }
                    }
                }
            } catch (e) {
                ErrorHandler.handleError(e);
            }
        }, 10);
    }

    updateButtonImage(url) {
        if (!this.button) return; // did we set it to button?
        if (url.slice(-3) === 'svg') {
            // svg replacement
            const client = new XMLHttpRequest();
            client.open('GET', url, true);
            client.setRequestHeader('Content-Type', 'image/svg+xml');
            client.onreadystatechange = () => {
                const contents = client.responseText;
                if (client.readyState === 4 && client.status === 200) {
                    // svg has headers and foters that we dont want.
                    // here we strip this
                    const svg = [];
                    let found = false;
                    let stop = false;
                    contents.split(/\n/).forEach(l => {
                        if (!stop) {
                            if (!found && l.indexOf('<svg') > -1) {
                                svg.push(l);
                                found = true;
                            } else if (found) {
                                svg.push(l);
                                if (l.indexOf('</svg') > -1) {
                                    stop = true;
                                }
                            }
                        }
                    });
                    const svgText = svg.join('\n');
                    this.button.innerHTML = svgText;
                    const buttonStyle = this.getSetting('buttonStyle');
                    if (buttonStyle) {
                        this.button.setAttribute('style', buttonStyle);
                    }
                    // button need some time to be rendered in the DOM
                    setTimeout(() => {
                        try {
                            // update global button
                            this.button = DialogHandler.frame.getElementById('messenger-button');
                            if (this.button) {
                                const newSVG = this.button.getElementsByTagName('svg')[0];
                                const buttonWidth = parseInt(this.button.clientWidth, 10);
                                const buttonHeight = parseInt(this.button.clientHeight, 10);
                                const svgWidth = newSVG.width.baseVal.value;
                                const svgHeight = newSVG.height.baseVal.value;
                                const marginLeft = Math.round(buttonWidth / 2) - Math.round(svgWidth / 2);
                                const marginTop = Math.round(buttonHeight / 2) - Math.round(svgHeight / 2);
                                newSVG.setAttribute('style', `margin-left:${marginLeft}px; margin-top:${marginTop}px;`);
                                const fr = DialogHandler.getWebmessengerFrame();
                                fr.removeAttribute('width');
                                this.button.addEventListener('click', DialogHandler.openWidget.bind(this, {}));
                            }
                        } catch (e) {
                            ErrorHandler.handleError(e);
                        }
                    }, 100);
                }
            };
            client.send();
        } else {
            // normal image replacement
            const oldWidth = this.button.clientWidth;
            this.button.innerHTML = `<img src="${url}"></img>`;
            // button needs some time to be rendered in the DOM
            // when done, we update frame width to button width
            this.updateFrameWidthToImageWidthWhenImageIsLoaded(oldWidth);
        }
    }

    clearHardStyleWhileTyping() {
        const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        if (isIE()) return;
        const sendButton = this.footer.getElementsByClassName('send')[0];
        if (!sendButton) return;

        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'attributes'
                    && mutation.attributeName === 'style'
                    && sendButton.getAttribute('style') !== ''
                ) {
                    sendButton.setAttribute('style', '');
                }
            });
        });

        observer.observe(sendButton, {
            attributes: true // configure it to listen to attribute changes
        });
    }

    // remove class that hides the chatwindow inside the iframe after we are done
    // making changes to the widget
    hideWidgetWhileLoading() {
        const widget = DialogHandler.getWidget();
        Helpers.addClass(widget, `${TARGET_DOMAIN}_hide_while_loading`);
    }

    addOverruledAgentLogoIfConfigured() {
        const url = this.getSetting('agentImageUrl', this.getSetting('companyImageUrl'));
        if (url) {
            const styleText = `
                .msg-wrapper .msg-avatar img {
                    content:url(${url});
                }
            `;
            StyleHandler.addStyleText(styleText, DialogHandler.frame);
        }
    }

    addStylesForOrientation() {
        if (SettingsHandler.orientation === 'bottom right') {
            StyleHandler.addStyleText(bottomRightCSS);
            StyleHandler.addStyleText(bottomRightFrameCSS, DialogHandler.frame);
        } else if (SettingsHandler.orientation === 'bottom left') {
            StyleHandler.addStyleText(bottomLeftCSS);
            StyleHandler.addStyleText(bottomLeftFrameCSS, DialogHandler.frame);
        } else if (SettingsHandler.orientation === 'top left') {
            StyleHandler.addStyleText(topLeftCSS);
            StyleHandler.addStyleText(topLeftFrameCSS, DialogHandler.frame);
        } else if (SettingsHandler.orientation === 'top right') {
            StyleHandler.addStyleText(topRightCSS);
            StyleHandler.addStyleText(topRightFrameCSS, DialogHandler.frame);
        }
    }

    // add a css file to the frame
    static addStyleText(text, f) {
        const doc = typeof (f) === 'undefined' ? window.document : f;
        const style = DialogHandler.frame.createElement('style');
        style.type = 'text/css';
        style.rel = 'stylesheet';
        style.innerHTML = text;
        if (doc && doc.head) {
            doc.head.appendChild(style);
        }
    }

    updateCssStrings() {
        // update the width and height of the bar if it is configured
        const brandColor = this.getSetting('customColors.brandColor', this.getSetting('brandColor', '#000000'));
        const actionColor = this.getSetting('customColors.actionColor', this.getSetting('actionColor', '#000000'));
        const convColor = this.getSetting('customColors.conversationColor', this.getSetting('conversationColor', '#000000'));
        const circleColor = this.getSetting('shoutoutCircleColor', '#00d86d');
        if (StyleHandler.displayStyle === 'bar') {
            let barWidth = this.getSetting('barWidth');
            let barHeight = this.getSetting('barHeight');
            if (barWidth) barWidth = parseInt(barWidth, 10);
            if (barHeight) barHeight = parseInt(barHeight, 10);
            const modifiedCSS = css
                .replace(/width:\s*"{{BARWIDTHCLOSED}}"/g, barWidth ? `width: ${barWidth}px;` : '')
                .replace(/height:\s*"{{BARHEIGHTCLOSED}}"/g, barHeight ? `height: ${barHeight}px;` : '')
                .replace('*{display:"{{DISABLEANITATIONS}}"}', DISABLEANITATIONMAINCSS)
                .replace(/BRANDCOLOR/g, brandColor)
                .replace(/CIRCLECOLOR/g, circleColor)
                .replace(/ACTIONCOLOR/g, actionColor)
                .replace(/CONVERSATIONCOLOR/g, convColor);
            const modifiedFrameCSS = framecss
                .replace(/width:\s*"{{BARWIDTHCLOSED}}"/g, barWidth ? `width: ${barWidth - 15}px;` : '')
                .replace(/height:\s*"{{BARHEIGHTCLOSED}}"/g, barHeight ? `height: ${barHeight}px;` : '')
                .replace('header:"{{HEADER}}"', barHeight ? 'height: inherit; line-height: inherit;' : '')
                .replace('*{display:"{{DISABLEANITATIONS}}"}', DISABLEANITATIONFRAMECSS)
                .replace(/BRANDCOLOR/g, brandColor)
                .replace(/CIRCLECOLOR/g, circleColor)
                .replace(/ACTIONCOLOR/g, actionColor)
                .replace(/CONVERSATIONCOLOR/g, convColor);
            // inject default frame widget css
            StyleHandler.addStyleText(modifiedFrameCSS, DialogHandler.frame);
            // inject default widget css
            StyleHandler.addStyleText(modifiedCSS);
        } else {
            const modifiedCSS = css
                .replace('*{display:"{{DISABLEANITATIONS}}"}', '')
                .replace(/width:\s*"{{BARWIDTHCLOSED}}"/g, '')
                .replace(/height:\s*"{{BARHEIGHTCLOSED}}"/g, '')
                .replace(/BRANDCOLOR/g, brandColor)
                .replace(/ACTIONCOLOR/g, actionColor)
                .replace(/CIRCLECOLOR/g, circleColor)
                .replace(/CONVERSATIONCOLOR/g, convColor);
            const modifiedFrameCSS = framecss
                .replace('*{display:"{{DISABLEANITATIONS}}"}', '')
                .replace(/width:\s*"{{BARWIDTHCLOSED}}"/g, '')
                .replace(/height:\s*"{{BARHEIGHTCLOSED}}"/g, '')
                .replace(/BRANDCOLOR/g, brandColor)
                .replace(/CIRCLECOLOR/g, circleColor)
                .replace(/ACTIONCOLOR/g, actionColor)
                .replace(/CONVERSATIONCOLOR/g, convColor);
            // inject default frame widget css
            StyleHandler.addStyleText(modifiedFrameCSS, DialogHandler.frame);
            // inject default widget css
            StyleHandler.addStyleText(modifiedCSS);
        }
    }

    // add a css file to the frame
    static addStyle(url) {
        // frame = typeof (f) === 'undefined' ? window.document : f;
        const link = DialogHandler.frame.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = url;
        if (DialogHandler.frame && DialogHandler.frame.head) {
            DialogHandler.frame.head.appendChild(link);
        }
    }

    moveStyleFromElementToFrameHead(element, id) {
        const styles = element ? element.getAttribute('style') || '' : '';
        let extraStyles = '';
        let extraPath = '';
        const brandColor = this.getSetting('customColors.brandColor', this.getSetting('brandColor'));
        if (element === this.button && brandColor) {
            extraStyles += `background-color: ${brandColor}`;
        }
        const conversationColor = this.getSetting('customColors.conversationColor', this.getSetting('conversationColor'));
        const conversationBackgroundImage = this.getSetting('backgroundImageUrl');
        if (element === this.conversation && conversationColor) {
            extraPath = '#container #conversation .row.right-row .msg-wrapper .msg';
            extraStyles += `background-color: ${conversationColor};`;
        }
        if (element === this.conversation && conversationBackgroundImage) {
            extraPath = '#container #conversation';
            extraStyles += `background: url(${conversationBackgroundImage});`;
        }
        if (element === this.header && brandColor) {
            extraStyles += `background-color: ${brandColor}`;
        }
        if (styles || extraStyles.length) {
            let styleText = '';
            if (extraPath.length === 0) {
                styleText = `
                    #${id} {
                        ${styles}
                        ${extraStyles}
                    }
                `;
            } else {
                styleText = `
                    #${id} {
                        ${styles}
                    }
                    ${extraPath} {
                        ${extraStyles}
                    }
                `;
            }
            StyleHandler.addStyleText(styleText, DialogHandler.frame);
        }
    }

    fixMessageStyle() {
        const messages = Array.csFrom(this.conversation.getElementsByClassName('msg'));
        messages.forEach(message => {
            message.setAttribute('style', '');
        });
    }

    removeConnectNotificationIfFound() {
        // the channel connection option message that sunshine is sending after the first message
        // is annoing and not needed and we are not able to remove it via sunshine options, so
        // we remove it manually with an MutationObserver.
        // we need the MutationObserver as the connectNotification message triggered by sunshine is
        // not triggering beforeDisplay delegate event.
        if (this.getSetting('showConnectNotification', false) === false) {
            try {
                // Options for the observer (which mutations to observe)
                const config = { attributes: true, childList: true, subtree: true };
                // Callback function to execute when mutations are observed
                let callbackCounter = 0;
                const callback = (mutationsList, observer) => {
                    // clear the observer event list;
                    observer.takeRecords();
                    // Use traditional 'for loops' for IE 11
                    mutationsList.forEach(mutation => {
                        if (mutation.type === 'childList') {
                            callbackCounter += 1;
                            // log(`observer hit counter: ${callbackCounter}`);
                            // the messages list has changed, let search and
                            // check if we can find the connectNotification message
                            const connectNotification = this.conversation.getElementsByClassName('connect-notification')[0];
                            if (connectNotification
                                && connectNotification.parentNode
                                && connectNotification.parentNode.parentNode) {
                                const msg = connectNotification.parentNode.parentNode;
                                if (msg) {
                                    // hide the message, deleting it will cause
                                    // sunshine react errors
                                    msg.style.display = 'none';
                                    // patch is done. stop observing
                                    observer.disconnect();
                                }
                            }
                        } else if (callbackCounter > 10) {
                            // after 10 message list changes we can savely assume
                            // the connectNotification is not send by sunshine (probably their
                            // integration has only one channel), so we can stop looking
                            observer.disconnect();
                        }
                    });
                };

                // Create an observer instance linked to the callback function
                const observer = new MutationObserver(callback);

                // Start observing the target node for configured mutations
                const targetNode = this.conversation.getElementsByClassName('messages')[0];
                observer.observe(targetNode, config);
            } catch (e) {
                // if something goes wrong, then never mind. let them show
                // the message...
            }
        }
    }

    // hard styles overrule and therefore overrrule css from file,
    // so we move them to style tags and dump them
    removeHardStyles() {
        // conversation cleanup
        if (!this.conversation) {
            return;
        }
        this.moveStyleFromElementToFrameHead(this.conversation, 'conversation');
        this.conversation.setAttribute('style', '');
        this.fixMessageStyle();
        this.removeConnectNotificationIfFound();
        // wrapper cleanup
        this.moveStyleFromElementToFrameHead(this.wrapper, 'wrapper');
        this.wrapper.setAttribute('style', '');
        // header cleanup
        this.moveStyleFromElementToFrameHead(this.header, 'header');
        this.header.setAttribute('style', '');
        // cleanup action buttons
        const buttons = Array.csFrom(this.conversation.getElementsByClassName('btn-primary'));
        buttons.forEach(b => b.removeAttribute('style'));
        // button cleanup
        if (this.button) {
            this.moveStyleFromElementToFrameHead(this.button, 'messenger-button');
            this.button.setAttribute('style', '');
            const oldsvg = this.button.getElementsByTagName('svg')[0];
            if (oldsvg) this.button.removeChild(oldsvg);
            if (!this.button.getElementsByClassName('logo')[0]) {
                const logo = '<div class="logo"></div>';
                if (this.button) {
                    this.button.appendChild(createElementFromHTML(logo));
                }
            }
        }
    }
}

export default StyleHandler;
