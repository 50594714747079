/*global TARGET_DOMAIN*/
import { Helpers } from "../helpers";
import DialogHandler from "./dialog-handler";
import SettingsHandler from "./settings-handler";
import { createElementFromHTML, hashFromDocumentUrl, findFunctionOnWindow, TRIGGER } from "./utils";
import { log } from "./utils";

let lastHiddenMessage = '';
let messageQueue = [];
let commandMessageQueue = '';
let debounceTimeout;
const debouncerDelay = 100;
const agentRealnameToAlias = {};


class MessageHandler {

    static get agentRealnameToAlias() {
        return agentRealnameToAlias;
    }
    // add a fake agent message(s) to the interface
    static showFakeAgentMessage(message, name, options) {
        const { onTop, uniqueId, noTime } = options || {};
        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        if (message && typeof message === 'string' && message !== '') {
            const messages = message.split('|||');
            if (!DialogHandler.conversation) {
                return;
            }
            const allMessages = DialogHandler.conversation.getElementsByClassName('row');
            const lastMessage = allMessages[allMessages.length - 1];
            if (!lastMessage || (lastMessage && !Helpers.hasClass(lastMessage, 'fake'))) {
                messages.forEach(mess => {
                    let avatar = '';
                    let time = '';
                    let from = '';
                    let pos = 'middle';
                    if (messages.indexOf(mess) === 0) {
                        pos = 'first';
                        const agentName = name || SettingsHandler.getSetting('agentName', 'Agent');
                        from = `<div class="from">${agentName}</div>`;
                    }
                    if (messages.indexOf(mess) === messages.length - 1) {
                        pos = 'last';
                        const agentImageUrl = SettingsHandler.getSetting(
                            'offlineAgentImageUrl',
                            SettingsHandler.getSetting(
                                'agentImageUrl',
                                SettingsHandler.getSetting(
                                    'companyImageUrl',
                                    'https://services.web1on1.com/image-dumper/uploads/chathelp.jpg'
                                )
                            )
                        );
                        avatar = `<img alt="avatar" src="${agentImageUrl}">`;
                        if (!noTime) {
                            time = `
                            <div class="message-status-indicator">
                                <span class="time"> ${SettingsHandler.language.messageRelativeTimeJustNow} </span>
                            </div>`;
                        }
                    }
                    const text = `
                        <div id="${uniqueId}" class="row left-row row-appmaker-${pos} fake">
                            ${from}
                            <div class="msg-wrapper">
                                <div class="msg-avatar">
                                    ${avatar}
                                </div>
                                <div class="msg" data-tip="9:49 AM" data-for="tooltip-5b1f7aed3b482d00229dad34" currentitem="true" style="width: 220px;">
                                    <div>
                                        <div>
                                            <span class="message-item message-text last-item">
                                                <span>
                                                    <span>${mess}</span>
                                                    <br>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="__react_component_tooltip place-bottom type-dark "  data-id="tooltip" style="left: 96px; top: 380px;">${hours}:${minutes}</div>
                            </div>
                            ${time}
                            <div class="clear"></div>
                        </div>`;
                    const conv = DialogHandler.conversation.getElementsByClassName('messages')[0];
                    if (conv) {
                        if (onTop) {
                            if (!uniqueId || conv.querySelector(`#${uniqueId}`) === null) {
                                conv.insertBefore(createElementFromHTML(text), conv.firstChild);
                            }
                        } else {
                            conv.appendChild(createElementFromHTML(text));
                        }
                    }
                });
            }
        }
    }

    static messagePromiseChain(messages) {
        let promise;
        if (messages.length) {
            for (const message of messages) {
                if (!promise) {
                    promise = SettingsHandler.sunshine.sendMessage(message);
                } else {
                    promise = promise.then(() => {
                        return SettingsHandler.sunshine.sendMessage(message);
                    });
                }
            }
        }
        return promise;
    }

    static messageDebouncer(message) {
        if (/>cs\s/.test(message)) {
            if (commandMessageQueue === '') {
                commandMessageQueue = message;
            } else {
                commandMessageQueue = `${commandMessageQueue};${message}`;
            }
        } else {
            messageQueue.push(message);
        }
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            if (commandMessageQueue) {
                messageQueue.push(commandMessageQueue);
            }
            commandMessageQueue = '';
            MessageHandler.messagePromiseChain(messageQueue).then(() => {
                messageQueue = [];
            });
        }, debouncerDelay);
    }

    static sendClientMessage(message) {
        if (message.csStartsWith(`${TRIGGER}`)) {
            if (lastHiddenMessage !== message) {
                // send same message only once, prefenting someone clicking a button many times
                // triggering token invalidation on the server
                lastHiddenMessage = message;
                MessageHandler.messageDebouncer(message);
            }
        } else {
            MessageHandler.messageDebouncer(message);
        }
    }

    static fixSeen() {
        const status = Array.csFrom(DialogHandler.conversation.getElementsByClassName('message-status-indicator'));
        const trans = SettingsHandler.getSetting('customText.messageSeen');
        if (trans) {
            status.forEach(s => {
                const seens = Array.csFrom(s.getElementsByTagName('b'));
                seens.forEach(seen => {
                    if (seen.innerHTML.match('Seen')) {
                        seen.innerHTML = seen.innerHTML.replace('Seen', trans); // eslint-disable-line
                    }
                });
            });
        }
    }

    static fixCardDoubleClick() {
        // sunshine carousel's keep link active after clicking, so a
        // client can click multiple times, which confuses the bots
        // so this patch replaces the button after clicking, so you
        // can only click ones
        const carousels = Array.csFrom(DialogHandler.conversation.getElementsByClassName('carousel-content'));
        carousels.forEach(message => {
            const buttons = Array.csFrom(message.getElementsByClassName('btn'));
            buttons.forEach(but => {
                but.addEventListener('click', () => {
                    try {
                        const child = document.createElement('a');
                        child.innerHTML = SettingsHandler.getSetting('customText.cardClickText', 'Selected');
                        child.className = 'btn disabled';
                        child.setAttribute(
                            'style',
                            'border-color: rgb(234, 234, 234) transparent transparent;'
                            + 'background-color: transparent;color: rgb(0, 153, 255);'
                            + 'border-width: 1px 0px 0px;border-top-style: solid;border-radius: 0px;'
                            + 'font-weight: 500;line-height: 1.5;height: 33px;'
                        );
                        but.replaceWith(child);
                    } catch (e) {
                        log('fixCardDoubleClick error', e);
                    }
                });
            });
        });
    }

    static activateBot(bot) {
        // it is a full bot command, just pass it on
        if (bot.match(/^>cs/)) {
            MessageHandler.sendClientMessage(bot);
        } else {
            // it is a bot name
            MessageHandler.sendClientMessage(`>cs ${bot} reset`);
        }
    }

    static checkBotStartSettings() {
        const startupBots = SettingsHandler.getSetting('bot');
        const text = SettingsHandler.getSetting('text');
        const prebottext = SettingsHandler.getSetting('preBotText', SettingsHandler.getSetting('prebottext'));
        log('checkBotStartSettings', startupBots);
        if (text || startupBots) {
            //openWidget();
            if (prebottext) {
                MessageHandler.showFakeAgentMessage(prebottext);
            }
            if (startupBots) {
                if (Array.isArray(startupBots)) {
                    startupBots.forEach(bot => MessageHandler.activateBot(bot.value));
                } else {
                    MessageHandler.activateBot(startupBots);
                }
            } else if (text) {
                MessageHandler.sendClientMessage(text);
            }
        }
    }

    static sendMessagesFromDataAttributes(item) {
        const attrBot = item.getAttribute(`data-${TARGET_DOMAIN}-bot`);
        const attrAgentText = item.getAttribute(`data-${TARGET_DOMAIN}-agent-text`);
        const attrAgentName = item.getAttribute(`data-${TARGET_DOMAIN}-agent-name`) || undefined;
        const attrClientText = item.getAttribute(`data-${TARGET_DOMAIN}-client-text`);
        if (attrBot && attrBot !== '') {
            if (attrBot.csStartsWith(TRIGGER)) {
                if (attrAgentName) {
                    attrBot
                        .match(/>cs\s+\S+/g)
                        .map(m => m.replace('>cs', '').trim())
                        .forEach(botname => { agentRealnameToAlias[botname] = attrAgentName; });
                }
                MessageHandler.sendClientMessage(attrBot);
            } else if (attrBot.length === 24 && attrBot.match(/[0-9a-f]+/)) {
                MessageHandler.sendClientMessage(`>cs-meta=bot:${attrBot},command=assign`);
            } else {
                if (attrAgentName) {
                    agentRealnameToAlias[attrBot] = attrAgentName;
                }
                MessageHandler.sendClientMessage(`${TRIGGER} ${attrBot} reset`);
            }
        }
        if (attrAgentText) {
            MessageHandler.showFakeAgentMessage(attrAgentText, attrAgentName);
        }
        if (attrClientText) { // legacy
            attrClientText.split('|||').forEach(clientMessage => {
                MessageHandler.sendClientMessage(clientMessage);
            });
        }
    }

    static processServerCommand(message) {
        // it is a command coming from the server, let processs it
        log('processServerCommand: ', message.text);
        if (message.text.csStartsWith(`${TRIGGER} track`)) {
            const params = message.text.replace(`${TRIGGER} track `, '').split(/\s+|\n/);
            const hashkeytocheck = parseInt(params.shift(), 10);
            const hashkeyfromurl = hashFromDocumentUrl();
            const funct = params.shift();
            const functOnWindow = findFunctionOnWindow(funct, window);
            if (hashkeytocheck && hashkeytocheck !== hashkeyfromurl) {
                log(`processServerCommand: hash differs, not processing command track (${hashkeytocheck} <=> ${hashkeyfromurl}) url=${document.location.href}`);
                //return;
            }
            if (functOnWindow && typeof functOnWindow === 'function') {
                try {
                    if (params.join(' ').match('{')) {
                        // Google Tag Manager Events
                        const dataToSend = JSON.parse(params.join(' '));
                        functOnWindow(dataToSend);
                    } else {
                        // Google Analytics Events
                        const dataToSend = params
                            .join(' ')
                            .split(',')
                            .map(e => e.trim())
                            .filter(e => e !== '');
                        functOnWindow.apply(null, dataToSend); // eslint-disable-line
                    }
                } catch (e) {
                    log('could not parse tracking info', params.join(' '));
                }
            } else {
                log(`did not find function ${funct}, could not send data`);
            }
        } else if (message.text.csStartsWith(`${TRIGGER} web2field`)) {
            const params = message.text.replace(`${TRIGGER} web2field `, '').split(/\s+|\n/);
            const bot = params.shift();
            const hashkeytocheck = parseInt(params.shift(), 10);
            const hashkeyfromurl = hashFromDocumentUrl();
            const field = params.shift();
            const funct = params.shift();
            const functOnWindow = findFunctionOnWindow(funct, window);
            if (hashkeytocheck && hashkeytocheck !== hashkeyfromurl) {
                log(`processServerCommand: hash differs, not processing command web2field (${hashkeytocheck} <=> ${hashkeyfromurl}) url=${document.location.href}`);
                //return;
            }
            log(`processServerCommand: hash matched, processing command (${hashkeytocheck} <=> ${hashkeyfromurl})`);
            if (functOnWindow && typeof functOnWindow === 'function') {
                try {
                    const path = params.join(' ').trim();
                    const answer = functOnWindow(path);
                    MessageHandler.sendClientMessage(`>cs ${bot} set ${field} ${answer}`);
                } catch (e) {
                    log('could not parse path or function error occured', params.join(' '));
                    MessageHandler.sendClientMessage(`>cs ${bot} error ${e.toString()}`);
                }
            } else {
                log(`did not find function ${funct}, could not send data`);
                MessageHandler.sendClientMessage(`>cs ${bot} error missing func ${funct}`);
            }
        } else if (message.text.csStartsWith(`${TRIGGER} widget close`)
            && SettingsHandler.sunshine && typeof SettingsHandler.sunshine.isOpened === 'function' && SettingsHandler.sunshine.isOpened()) {
            SettingsHandler.sunshine.close();
        } else if (message.text.csStartsWith(`${TRIGGER} widget open`)
            && SettingsHandler.sunshine && typeof SettingsHandler.sunshine.isOpened === 'function' && !SettingsHandler.sunshine.isOpened()) {
            DialogHandler.openWidget({ label: 'openened_by_servercommand' });
        }
    }
}

export default MessageHandler;
